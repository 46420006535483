import { NavLink } from "react-router-dom";
import { useState } from "react";
import About from "../data/about.json"
function Nav() {

  const [isNavOpen, setIsNavOpen] = useState(false)
  
  return(

    <div>
    <section className="MOBILE-MENU flex absolute z-50 w-full  pl-5 pt-3 m-0 lg:hidden">
      <div
        className="HAMBURGER-ICON space-y-2 bg-white p-3 rounded-xl hover:cursor-pointer"
        onClick={()=> setIsNavOpen((prev) => !prev)}
      >    
    
        <span className="block h-1 w-8  bg-gray-900"></span>
        <span className="block h-1 w-8  bg-gray-900"></span>
        <span className="block h-1 w-8  bg-gray-900"></span>
      </div>

    <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
      <div
      className="CROSS-ICON absolute top-0 right-0 px-8"
      onClick={()=> setIsNavOpen(false)}
    >

    <svg
    className="h-8 z-50 w-8 text-gray-600 "
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
    </div>
            <ul className="MENU-LINK-MOBILE-OPEN  flex flex-col items-center justify-between min-h-[250px]">
              <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/">Home</a>
              </li>              <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/about">About</a>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/support">Support Us</a>
              </li>
        <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
    </section>


    <div className="fixed DESKTOP-MENU hidden spac-x-8 lg:flex  transition ease-in-out delay-15 flex flex-row  top-0 z-50 w-full h-28  text-xl text-white justify-end drop-shadow-2xl  items-center bg-[#254151] ">
    <div className="space-x-9">
    <NavLink className="hover:underline" to="/"onClick={() => {window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
  });}}> Home </NavLink>
    <NavLink className="hover:underline" to="/about"onClick={() => {window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
  });}}> Who we are</NavLink>
    <NavLink className="hover:underline" to="/support"onClick={() => {window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
  });}}> Support Us </NavLink>
    <NavLink className="hover:underline" to="/events"onClick={() => {window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
  });}}> Events </NavLink>
    <NavLink className="hover:underline" to="/news"onClick={() => {window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
  });}}> News </NavLink>
    <NavLink className="hover:underline" to="/contact"onClick={() => {window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
  });}}> Contact </NavLink>
    <NavLink to={About[0].pay} className="px-10 py-11 text-cyan-950 bg-amber-300 hover:bg-red-600 hover:text-white hover:underline"> Donate </NavLink>
    </div>
    </div>
    <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
    </div>
  )
}

export default Nav;
