import { useState } from "react";
import axios from 'axios'
//import { Link } from "react-router-dom";
function Footer () {

const [email, setEmail] = useState("")

const postData = async () => {
 await axios.post("https://peoplethatcare.info/email", {email})
 }
  function handleChange(e){
   setEmail(e.target.value);
  }

 function handleSubmit(e) {
   e.preventDefault();
   postData();
 }
//const inputCard = document.getElementById("input-card")

/*inputCard.addEventListener("keypress", function(event){
  if (event.key === "Enter") {
    event.preventDefault();
    document.getElementById("myBtn").click()
  }
})*/



  
  return(
<footer className="Footer-wrapper flex flex-col bg-white">
    <div className="details-wrapper flex justify-around pb-20">
    <div className="contact flex flex-col justify-center items-center">
    <div className="flex text-2xl font-bold pb-3 uppercase">Contact</div>
    <div>
    P.O. Box 857
    </div>
    <div>
    Lineville AL 36366
    </div>
    <div>
    contact@peoplewhocare.info
    </div>
    
    <div>
    (256)396-0188    
    </div>

    </div>
    {/* 
    <div className="connect flex flex-col justify-center items-center">
    <div className="flex text-2xl font-bold pb-3 uppercase"> Connect with us </div>
    <div>Facebook</div>
    <div>Instagram</div>
    <div>Twitter</div>
    </div>
    <div className="policies flex flex-col justify-center items-center">
    <div className="flex text-2xl font-bold pb-3 uppercase">Policies </div>
    <div>
    Terms & Conditions
    </div>
    <div>
    Privacy
    </div>
    <div>
    Accessibility Statement
    </div>
    </div>
*/}
    <div className="subscribe flex flex-col text-center justify-center items-center">
    <div className="flex text-2xl font-bold pb-3 uppercase "> Subscribe </div>
    <div className=" ">
    <input id="input"  placeholder="Enter Email"  value={email} onChange={handleChange}className="border-b-2 outline-none pr-2 box-border text-center border-gray-900 mr-1 w-11/12 "> 
    </input>
    <button id="myBtn" onClick={handleSubmit} className="px-8 py-1 mt-3 bg-sky-400 text-center justify-center items-center rounded-xl">Join Newsletter</button>
    </div>
    </div>
    </div>
    <div className=
    "flex bottom-0 w-full text-2xl text-sky-950 justify-center drop-shadow-2xl items-center justify-center text-center font-bold bg-blue-300 p-8">
    Registered Charity Number :170(b)(1)(A)(vi) 
    </div>
</footer>
  )
}
export default Footer;
