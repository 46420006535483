import NewsHighlights from "../components/NewsHighlights";
function News(){
  return(
    <div className="static top-0 text-3xl text-sky-950 bg-slate-50 h-full min-h-screen w-full flex  justify-center drop-shadow-2xl items-center font-bold">
    
    <NewsHighlights />

    </div>
  )
}
export default News ;
