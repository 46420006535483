// Routes 
import Home from "./pages/home.js";
import About from "./pages/about.js";
import Support from "./pages/support.js";
import Events from "./pages/events.js";
import News from "./pages/news.js";
import Contact from "./pages/contact.js";

// Every Page
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/nav.js"
import Footer from "./components/footer.js";

// Font Awesome Global Imports
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {   faFacebook, faInstagram, faXTwitter } from "@fortawesome/free-brands-svg-icons";
library.add(fas,  faFacebook, faInstagram, faXTwitter);


function App() {
  
  

  return (
    <div className="App-Warpper w-screen ">
      <BrowserRouter>
        <Nav />
    {/*<Sidenav />*/}
    <div className=" ">
    <Routes>
      <Route path ="/" element={<Home/>} />
      <Route path ="/about" element={<About/>} />
      <Route path ="/support" element={<Support/>} />
      <Route path ="/events" element={<Events/>} />
      <Route path ="/news" element={<News/>} />
      <Route path ="/contact" element={<Contact/>} />
    </Routes>    
    </div>
    <Footer />
      </BrowserRouter>
    </div>
  );
}
export default App;  
