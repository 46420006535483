import { NavLink } from "react-router-dom";
import News from "../data/news.json"
import About from "../data/about.json"
function Support(){

  console.log(News[0])
  return(
    <div className="flex-col static top-0 text-3xl text-sky-950 bg-slate-50 h-full min-h-screen w-full flex  drop-shadow-2xl items-center font-bold">
    <div className="max-sm:py-32 py-40 ">
    Support
</div>
    {/* <div className=" text-xl text-center w-30 text-wrap mb-36"> Our organization is made possible through people like you please consider making a donation to help us continuie our work by clicking the donate link below</div>
    */} 
    <div className=" text-xl text-center w-30 text-wrap mb-36"> {News[0].content}</div>
    <NavLink className=" text-xl text-center w-30 text-wrap  hover:underline" to={News[0].link}> {News[0].content_2} </NavLink>
    <NavLink className="bg-amber-300 rounded-2xl p-5 my-9 hover:bg-red-600 hover:text-white hover:underline" to={About[0].pay}> Donate </NavLink>
    </div>
  )
}
export default Support ;
