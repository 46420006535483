import { Link } from 'react-router-dom';
//import Highlights from '../data/news.json'
//import newNewsHighlights from './newNewsHighlights';
import News from '../data/news.json'
function NewsHighlights () {
  //{ 
    //  Highlights.map( highlight => {
    //    return(
    //    <div className='flex flex-col w-1/3 text-wrap text-center self-center justify-center '>
    //    <div className='bg-sky-300 rounded-xl text-stone-800'>
    //    {highlight.title}
    //    </div>
    //    <div className='box-border text-wrap text-center'>
    //    {highlight.content}
    //    {/* Bug in Href use Link built in React component*/}
    //    <a className='hover:underline text-sky-400 rounded-md mt-7' href={highlight.link}> {highlight.linkName}</a>
    //    </div>
    //    </div>
    //  )
    //})
    //}
  return (
    <div className=" flex-col text-center static top-0 text-3xl text-sky-950 bg-slate-50 h-full min-h-screen w-full flex  justify-center justify-items-center items-center drop-shadow-2xl py-32 font-bold">

    <div className='header underline mb-40'>
    News
    </div>

<div className='news-wrapper  grid grid-rows-3 grid-flow-col  gap-4 w-full h-full justify-center justify-items-center self-center text-center '>
 <div className='text-xl'>
    <div className="text-xl text-center w-30 text-wrap ">
    {News[1].title}
    </div> 
    <div className="text-xl text-center w-30 text-wrap ">
    {News[1].content}
    </div> 
    <div className="text-xl text-center w-30 text-wrap ">
    {News[1].content_2}
    </div> 
    <div className="text-xl text-center w-30 text-wrap mb-36">
    {News[1].content_3}
    </div> 
    <Link className="bg-amber-400 rounded-2xl p-5 my-9 hover:bg-red-600 hover:text-white hover:underline" to={News[1].link}>{News[1].linkName}</Link>

    </div> 
    
   
    </div>
    </div>

  )
}
export default NewsHighlights;
