import Highlights from "../data/events.json"
function EventsHighlights () {

/*{
      Highlights.map( highlight => {
      return(
        <div className='flex flex-col w-1/3 text-wrap text-center self-center justify-center '>
        <div className='bg-red-300 rounded-xl text-stone-800'>
        {highlight.title}
        </div>
        <div className='box-border text-wrap text-center'>
        {highlight.content}
        <a className='hover:underline text-red-400 rounded-md ' href={highlight.link}> {highlight.linkName}</a>
        </div>
       
        </div>
      )
    })}
*/

  return (

    <div className=" flex-col text-center static top-0 text-3xl text-sky-950 bg-slate-50 h-full min-h-screen w-full flex  justify-center justify-items-center items-center drop-shadow-2xl py-32 font-bold">

    <div className='header underline mb-40'>
    Events
    </div>

<div className='news-wrapper  grid grid-rows-3 grid-flow-col  gap-4 w-full h-full justify-center justify-items-center self-center text-center '>
    <div className="text-xl">
    No Events scheduled at this time. 
    </div>
    </div>
    </div>



  )
}
export default EventsHighlights;
