import  Info  from "../data/about.json";
function About(){
  const data = Info[0]
  return(
    <div className="static top-0  text-sky-950 bg-slate-50 h-full min-h-screen w-full flex  drop-shadow-2xl items-center font-bold flex-col">

   <div className="text-center items-center text-4xl pt-5 mt-28 mb-28"> About </div>
   <div className="wrapper flex  text-center w-full justify-center items-center content-between  px-48 mt-32">
   <div className="text-center pr-2 w-auto justify-center  sm:mx-12 items-center text-2xl pb-10"> 
    
    <div className="text-3xl">{data.orginazation}</div>
    <div className="text-xl text-sky-900">{data.mission}</div>
    
    </div>   
   <ul className="text-xl md:w-1/2 pl-2">
    <li className="border border-b-4 border-red-300 border-t-0 border-x-0 "> President: {data.president} </li>
    <li className=" border border-b-4 border-red-300 border-t-0 border-x-0 "> Vice President: {data.vice_president} </li>
    <li className=" border border-b-4 border-red-300 border-t-0 border-x-0 "> Treasurer: {data.treasury} </li>
    <li className=" border border-b-4 border-red-300 border-t-0 border-x-0 "> Secretary: {data.secretary} </li>
    <li className=" border border-b-4 border-red-300 border-t-0 border-x-0 "> Advisor: {data.advisor1} </li>
    <li className=" border border-b-4 border-red-300 border-t-0 border-x-0 "> Advisor: {data.advisor2} </li>
    </ul>
    </div>
    </div>
  )
}
export default About;
