import NewsHighlights from "../components/NewsHighlights.js";
import newNewsHighlights from "../components/newNewsHighlights.js";
import EventsHighlights from "../components/EventsHighlights";
import video from "../media/video/11490-230853032.mp4"
import donate_image from "../media/images/banner.jpg"
import axios from "axios"
import {  useState } from "react";
import Info from "../data/about.json"
import About from "../data/about.json";
import News from "../data/news.json"
import { NavLink } from "react-router-dom";
function Home () {

const [email, setEmail] = useState("")


  function handleChange(e){
   setEmail(e.target.value);
  }

 const postData = async () => {
 await axios.post("https://peoplethatcare.info/email", {email})
 }
 function handleSubmit(e) {
   e.preventDefault();
    postData();
 }
 

  return(
<div className=" ">

    <div className="relative flex items-center justify-center  h-screen  overflow-hidden">
    <div className="absolute w-full h-full z-40 bg-sky-500 bg-opacity-30" />
    <div className="absolute z-30 p-5 text-5xl font-bold underline  text-white rounded-xl"> 
    People that care!
    </div>

<div dangerouslySetInnerHTML={
  {
    __html: 
`
    <video class="relative z-10 w-auto min-w-full min-h-full max-w-none" autoplay="autoplay" loop="loop" muted defaultMuted playsinline oncontextmenu="return false;" preload="auto" src="${video}" type="video/mp4"  />
` 
  }
}/>
    
  {/* 
    //<video className="absolute z-10 w-auto min-w-full min-h-full max-w-none" autoPlay muted playsInline loop>
    //<source src={video} type="video/mp4" />
    //</video> 
     */}
    </div>

    <div className="hero-card grid grid-cols-2 my-auto  self-center w-screen">
      <div className="mission flex items-start justify-center  bg-white h-96 w-full ">
    {Info.map(info => { return (
      <div className='wrapper mt-20 flex flex-col sm:w-1/2 text-center items-center justify-center'>
        <div className='text-4xl mt-2 mb-5'>Mission</div>
        <div className="h-20"> {info.mission} 
        <NavLink className="mt-5 text-sky-500" to="/about"onClick={() => {window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
  });}}> Read more </NavLink>
      </div>
      </div>
     )})}
    </div>
      <div className="support text-center flex-col justify-center items-center  bg-red-400 text-white h-96 w-full">

    <div className="wrapper h-full w-full content-center"> 
    <div className="text-4xl pt-8 mb-5"> Take a Pledge </div>
    <NavLink className=" p-2  rounded-2xl bg-white text-red-400 hover:text-cyan-400" to={Info[0].pay}> Support Us </NavLink>
    </div>
    </div>
      <div className="news flex justify-center items-center bg-sky-500 text-white h-96 w-full">
    
 <div className='wrapper justify-center text-center'>
 <div className='text-4xl mb-5'>Latest News</div>
  <div className=''> {News[1].title}</div>
  <div className='mb-5'> {News[1].content}</div>
  <NavLink className=" p-2  rounded-2xl bg-white text-sky-400 hover:text-cyan-400" to="/news" 

onClick={() => {window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
  });}}

  > Learn More </NavLink>
</div>
    </div>
      <div className="subscribe flex flex-col justify-center items-center bg-blue-900 text-white h-96 w-full pl-2">
    <div className="text-4xl mb-2">
    Subscribe
    </div>
    <div className="max-sm:hidden text-gray-300 mb-5"> Subscribe to our newsletter </div>
    <form className="flex flex-col">
<input  id="input-card" placeholder="Enter Email Here*" onSubmit={handleSubmit}   onChange={handleChange} className="text-white pr-2 text-center outline-none bg-transparent box-border w-11/12 border-b-2 border-gray-300  mr-1" > 
    </input>
    <button id="myBtn" type="button"  onClick={handleSubmit} className="px-8 py-1  text-center justify-center items-center rounded-xl box-border border-transparent  border-2 hover:bg-transparent hover:text-sky-300 transition">Join </button>
</form>
    </div>
    </div>
    
    <EventsHighlights />
    <div className="donate-wrapper flex relative h-36 w-full z-40 rounded bg-opacity-50 text-white items-center justify-evenly ">
    <div className="flex-1 absolute w-full h-full z-20 bg-white object-none overflow-hidden bg-opacity-30"  />
    
    <img src={donate_image} type="image/jpg" alt=" " className=" flex absolute object-none  w-full h-full bg-cover bg-fixed  z-10 overflow-hidden bg-white" />
    <div className="relative z-30 text-3xl font-bold">
    People that Care
    </div>
    <NavLink className=" z-50 bg-white text-sky-500 rounded-xl p-4 hover:cursor-pointer hover:bg-red-600 hover:text-white hover:underline  transition ease-in-out delay-15 " to={About[0].pay}>
    DONATE NOW!
    </NavLink>
    </div>
    
    <NewsHighlights />
    
    </div>
  )
}
export default Home;
